import { Provider } from 'react-redux';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import Psd from './pages/Psd';
import Prd from './pages/Prd';
import Excel from './pages/Excel';
import SocialFinancing from './pages/SocialFinancing';
import ReconciliationTool from "./pages/ReconciliationTool";
import Despair from "./pages/Despair"
import store from './store';
import SettlementSheet from './pages/SettlementSheet';
import OperationalHedging from "./pages/OperationalHedging";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/psd" render={() => <Psd />} />
          <Route path="/operationalHedging" render={() => <OperationalHedging />} />
          <Route path="/prd" render={() => <Prd />} />
          <Route path="/socialFinancing" render={() => <SocialFinancing />} />
          <Route path="/settlementSheet" render={() => <SettlementSheet />} />
          <Route path="/reconciliationTool" render={() => <ReconciliationTool />} />
          <Route path="/despair" render={() => <Despair />} />
          <Route path="/Excel" render={() => <Excel />} />
          <Route path="/" render={() => <Home />} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
