import { List, Node } from ".";
const parse = require('csv-parse/lib/sync')

const regexp = /^[A-z]{1,4}.*$/
export function readCSV(file: Blob, format: (val: string[]) => Node): Promise<List> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      const parser = parse(this.result, {
        on_record: (row: string[]) => {
          if (regexp.test(row[0])) {
            return format(row)
          } else {
            console.log("判空的行", row);
            return null
          }
        }
      })
      resolve(parser)
    };
    reader.onerror = function () {
      reject("csv文件读取失败！")
      console.log('csv文件读取失败！');
    };
    reader.readAsText(file, 'gb2312')
  })
}