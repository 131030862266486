import { Button, Col, message, Modal, Row, Select, Tag } from 'antd';
import { useCallback, useRef, useState, useEffect, type FC } from 'react';
import styled from 'styled-components';
import { subtotalLists } from './config';
import { flatten, assign, difference } from 'lodash';
import { CloseOutlined } from '@ant-design/icons';

const ConfigExport: FC<{ namesMore: { key: string; value: string }[] }> = ({ namesMore }) => {
  const [subtotalListsState, setSubtotalListsState] = useState(subtotalLists);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectData, setSelectData] = useState('');
  const [showOptions, setShowOptions] = useState<{ key: string; value: string }[]>([]);
  const addMoreRow = useCallback(() => {
    console.log('row');
    setSubtotalListsState((old) => [...old, []]);
  }, []);
  const [messageApi, contextHolder] = message.useMessage();

  const showModelRow = useRef(-1);
  const addMoreCol = useCallback((rIndex) => {
    console.log('col');
    showModelRow.current = rIndex;
    setIsModalOpen(true);
  }, []);

  const [namesMoreMap, setNamesMoreMap] = useState<Record<string, { key: string; value: string }>>({});
  useEffect(() => {
    setNamesMoreMap(assign({}, ...namesMore.map((el) => ({ [el.key]: el }))));
  }, [namesMore]);

  useEffect(() => {
    const lv1subtotalListsState = flatten(subtotalListsState);
    const namesMoreKeys = namesMore.map((el) => el.key);
    const diff = difference(namesMoreKeys, lv1subtotalListsState);
    const arr: { key: string; value: string }[] = [];
    diff.forEach((key) => {
      arr.push(namesMoreMap[key]);
    });
    setShowOptions(arr);
  }, [namesMore, namesMoreMap, subtotalListsState]);

  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
  }, []);
  const handleOk = useCallback(() => {
    if (!selectData) {
      messageApi.info('选项不能为空');
      return;
    }
    if (showModelRow.current !== -1) {
      setSubtotalListsState((old) => {
        old[showModelRow.current].push(selectData);
        const newS = old.filter((row) => row.length);
        localStorage.setItem('psd_subtotalLists_local_config', JSON.stringify(newS));
        subtotalLists.splice(0, subtotalLists.length, ...newS);
        return newS;
      });
      setIsModalOpen(false);
    }
  }, [messageApi, selectData]);

  const deleteIt = useCallback((ridx: number, cidx: number) => {
    setSubtotalListsState((old) => {
      old[ridx].splice(cidx, 1);
      const newS = old.filter((row) => row.length);
      localStorage.setItem('psd_subtotalLists_local_config', JSON.stringify(newS));
      subtotalLists.splice(0, subtotalLists.length, ...newS);
      return newS;
    });
  }, []);

  useEffect(() => {
    if (!isModalOpen) {
      setSelectData('');
    }
  }, [isModalOpen]);

  return (
    <>
      {contextHolder}
      <Modal title="添加统计项" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Row align="middle">
          <Col span={6}>
            <LabelModel>请选择新项：</LabelModel>
          </Col>
          <Col span={18}>
            <Select
              value={selectData}
              style={{ width: '100%' }}
              fieldNames={{ label: 'key', value: 'key' }}
              placeholder={'请选择名称'}
              onChange={setSelectData}
              options={showOptions}
            />
          </Col>
        </Row>
      </Modal>
      <h3>导出分组配置（修改导出配置后必须刷新页面后使用）</h3>
      <div>
        {subtotalListsState.map((row, rIndex) => (
          <Row key={rIndex} style={{ marginBottom: '6px' }}>
            {row.map((cell, index) => (
              <Tag style={{ position: 'relative', width: '40px', textAlign: 'center' }} key={index}>
                {cell}
                {namesMoreMap[cell] && (
                  <Colse onClick={() => deleteIt(rIndex, index)}>
                    <CloseOutlined style={{ fontSize: '10px' }} />
                  </Colse>
                )}
              </Tag>
            ))}
            <Button size="small" onClick={() => addMoreCol(rIndex)} title="添加列">
              +
            </Button>
          </Row>
        ))}
        <Row>
          <Button size="small" onClick={addMoreRow} title="添加行">
            ++
          </Button>
        </Row>
      </div>
    </>
  );
};

export default ConfigExport;

const LabelModel = styled.span`
  color: #666;
  font-size: 15px;
`;

const Colse = styled.div`
  position: absolute;
  border-radius: 50%;
  right: -8px;
  top: -8px;
  width: 16px;
  height: 16px;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 6px #666;
  color: #333;
  text-align: center;
  line-height: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: rgba(0, 0, 0, 0.6);
  }
`;
