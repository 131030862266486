import { defaults } from "lodash"
import { CellOption } from "./types"

// 通用单元格配置信息（垂直永远居中）
export let cellDeault: CellOption = {
  width: 80, // 单元格宽度
  height: 24, // 单元格高度
  // 上下左右滚动一次步长
  scrollHorizontalBtnStep: 160,
  scrollVerticalBtnStep: 48,
  // 单元格内边距
  paddingLeft: 4,
  paddingRight: 4,
  // fillText属性
  textAlign: "right",
  fillStyle: "#000",
  fontSize: 14,
  fontWeight: "normal",
  fontFamily: "arial"
}

// 用于改变左侧判断内容过早消失偏移量
export const offsetCheckLeft = 80
export const offsetCheckTop = 24

// 初始化全局默认配置项（仅在项目启动时执行）
export function initDefaultCellOption(option: Partial<CellOption> | null = null) {
  return cellDeault = defaults(option, cellDeault)
}
