import ReactDOM from 'react-dom';
import { enableES5 } from 'immer';
import dayJs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { ConfigProvider, Empty } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import App from './App';
import relativeTime from 'dayjs/plugin/relativeTime';
import reportWebVitals from './reportWebVitals';
import '@csstools/normalize.css';
import 'antd/dist/antd.css';

enableES5();

dayJs.extend(relativeTime);
dayJs.locale('zh-cn');

const { NO_DATA } = Empty;

const customizeRenderEmpty = () => <Empty type={NO_DATA} />;

ReactDOM.render(
  <ConfigProvider locale={zhCN} renderEmpty={customizeRenderEmpty}>
    <App />
  </ConfigProvider>,
  document.getElementById('root'),
);

reportWebVitals();
