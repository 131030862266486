export const names: { [propname: string]: string } = {
  IC: '中证500',
  IF: '沪深300',
  IH: '上证50',
  IM: '中证1000',
  FG: '玻璃',
  HC: '热卷',
  I: '铁矿石',
  J: '焦炭',
  JM: '焦煤',
  RB: '螺纹钢',
  SF: '硅铁',
  SM: '锰硅',
  ZC: '动力煤',
  AG: '白银',
  AL: '铝',
  AU: '黄金',
  CU: '铜',
  NI: '镍',
  PB: '铅',
  SN: '锡',
  ZN: '锌',
  BU: '沥青',
  EB: '苯乙烯',
  EG: '乙二醇',
  L: '塑料',
  LU: '低硫燃料油',
  MA: '甲醇',
  PG: '液化气',
  PP: '聚丙烯',
  RU: '橡胶',
  SC: '原油',
  TA: 'PTA',
  V: 'PVC',
  PF: '涤纶短纤',
  UR: '尿素',
  FU: '燃料油',
  A: '豆一',
  AP: '苹果',
  B: '豆二',
  C: '玉米',
  CF: '棉花',
  CJ: '红枣',
  CS: '玉米淀粉',
  CY: '棉纱',
  LH: '生猪',
  M: '豆粕',
  P: '棕榈油',
  RM: '菜粕',
  Y: '豆油',
  OI: '菜籽油',
  PK: '花生仁',
  JD: '鸡蛋',
  SS: '不锈钢',
  SA: '纯碱',
  SP: '纸浆',
  NR: '20号胶',
  SR: '白糖',
  SI: '工业硅',
  T: '十债',
  TS: '二债',
  TF: '五债',
  TL: '三十债',
  AO: '氧化铝',
};
// 每手乘数
export const mscs: { [key: string]: number } = {
  IC: 200,
  IF: 300,
  IH: 300,
  IM: 200,
  FG: 20,
  HC: 10,
  I: 100,
  J: 100,
  JM: 60,
  RB: 10,
  SF: 5,
  SM: 5,
  ZC: 100,
  AG: 15,
  AL: 5,
  AU: 1000,
  CU: 5,
  NI: 1,
  PB: 5,
  SN: 1,
  ZN: 5,
  BU: 10,
  EB: 5,
  EG: 10,
  L: 5,
  LU: 10,
  MA: 10,
  PG: 20,
  PP: 5,
  RU: 10,
  SC: 1000,
  TA: 5,
  V: 5,
  PF: 5,
  UR: 20,
  FU: 10,
  A: 10,
  AP: 10,
  B: 10,
  C: 10,
  CF: 5,
  CJ: 5,
  CS: 10,
  CY: 5,
  LH: 16,
  M: 10,
  P: 10,
  RM: 10,
  Y: 10,
  OI: 10,
  PK: 5,
  JD: 10,
  SS: 5,
  SA: 20,
  SP: 10,
  NR: 10,
  SR: 10,
  SI: 5,
  T: 10000,
  TS: 20000,
  TF: 10000,
  TL: 10000,
  AO: 20,
};

// 保证金
export const bzj: { [key: string]: string } = {
  IC: '14%',
  IF: '12%',
  IH: '12%',
  IM: '15%',
  FG: '9%',
  HC: '13%',
  I: '13%',
  J: '20%',
  JM: '20%',
  RB: '13%',
  SF: '12%',
  SM: '12%',
  ZC: '50%',
  SS: '14%',
  AG: '12%',
  AL: '12%',
  AU: '8%',
  CU: '12%',
  NI: '19%',
  PB: '14%',
  SN: '14%',
  ZN: '14%',
  BU: '15%',
  EB: '12%',
  EG: '12%',
  L: '11%',
  LU: '15%',
  MA: '8%',
  PG: '12%',
  PP: '11%',
  RU: '10%',
  SC: '15%',
  TA: '7%',
  V: '11%',
  PF: '8%',
  UR: '7%',
  FU: '15%',
  SA: '9%',
  SP: '15%',
  NR: '10%',
  A: '12%',
  AP: '8%',
  B: '9%',
  C: '12%',
  CF: '7%',
  CJ: '12%',
  CS: '9%',
  CY: '7%',
  LH: '15%',
  M: '10%',
  P: '12%',
  RM: '8%',
  Y: '9%',
  OI: '9%',
  PK: '8%',
  JD: '9%',
  SR: '7%',
  SI: '10%',
  T: '2%',
  TS: '0.5%',
  TF: '1%',
  TL: '3.5%',
  AO: '9%',
};

// 小计分组
export const subtotalLists: string[][] = JSON.parse(
  localStorage.getItem('psd_subtotalLists_local_config') ||
    `[
  ["IM", "IC", "IF", "IH"],
  ["TS", "TF", "T", "TL"],
  ["FG", "HC", "I", "J", "JM", "RB", "SF", "SM", "ZC", "SS"],
  ["AG", "AL", "AU", "CU", "NI", "PB", "SN", "ZN", "SI", "AO"],
  ["BU", "EB", "EG", "L", "LU", "MA", "PG", "PP", "RU", "SC", "TA", "V", "PF", "UR", "FU", "SA", "SP", "NR"],
  ["A", "AP", "B", "C", "CF", "CJ", "CS", "CY", "LH", "M", "P", "RM", "Y", "OI", "PK", "JD", "SR"]
]`,
);
