import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import models from './models';
import createLoadingPlugin from '@rematch/loading';
import { RootModel } from '@/models/types';

const loadingPlugin = createLoadingPlugin();

export const store = init({
  models,
  plugins: [loadingPlugin],
  redux: {
    devtoolOptions: {
      disabled: process.env.NODE_ENV === 'production',
    },
  },
});

interface ILoadingPlugin {
  loading: {
    models: RematchRootState<RootModel>;
    effects: Dispatch;
  };
}

export type Store = typeof store;
export type IRootState = RematchRootState<RootModel> & ILoadingPlugin;
export type Dispatch = RematchDispatch<RootModel>;

export default store;
