import withContext from "@/utils/withContext"
import { Button } from "antd"
import { useCallback, useState } from "react"
import styled from "styled-components"
import SelectFile from "./SelectFileOperate"

const [useCtx1, Provider] = withContext<{}>({})

function Despair() {
  const [selectComponents, setSelectComponents] = useState<JSX.Element[]>([])
  const addSelectFileComponent = useCallback(() => {
    setSelectComponents(old => {
      old.push(<SelectFile />)
      return [...old]
    })
  }, [])
  return (
    <Provider>
      <Container>
        <Button onClick={addSelectFileComponent}>添加导入</Button>
        <br />
        {selectComponents}
      </Container>
    </Provider>
  )
}

const Container = styled.div`
  padding: 15px;
`
export default Despair
export const useCtx = useCtx1