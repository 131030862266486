import { RootModel } from '@/models/types';

const models = {} as RootModel;

(require as any)
  .context('./', false, /\.(js|ts)$/)
  .keys()
  .forEach((path: any) => {
    const modulePath = path.replace('./', '');
    if (!/^(index\.[tj]s)/.test(modulePath)) {
      const module = require(`./${modulePath}`);
      Object.assign(models, {
        [modulePath.replace(/\.js$/, '').replace(/\.ts$/, '')]: module.default,
      });
    }
  });

export default models;
