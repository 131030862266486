import { CellInfo } from "../types";

// 初始化笔刷配置
export function brush(ctx: CanvasRenderingContext2D, { width, color } = { width: 1, color: "rgb(238, 238, 237)" }) {
  ctx.lineWidth = width;
  ctx.strokeStyle = color;
}

// 绘制边框线条(水平绘制)  ctx 绘制位置    线条长度
export function lineHorizontal(ctx: CanvasRenderingContext2D, x: number, y: number, width: number) {
  // console.log(x, y, width);
  ctx.beginPath()
  const y_ = y + 0.5
  ctx.moveTo(x, y_)
  ctx.lineTo(width + x, y_)
  ctx.stroke()
  ctx.closePath()
}
// 绘制边框线条(垂直绘制)  ctx 绘制位置  线条长度
export function lineVertical(ctx: CanvasRenderingContext2D, x: number, y: number, height: number) {
  ctx.beginPath()
  const x_ = x + 0.5
  ctx.moveTo(x_, y)
  ctx.lineTo(x_, height + y)
  ctx.stroke()
  ctx.closePath()
}

export function text(ctx: CanvasRenderingContext2D, ci: CellInfo, left: number, top: number) {
  ctx.fillStyle = ci.fillStyle;
  ctx.font = `${ci.fontSize}px ${ci.fontWeight} ${ci.fontFamily}`
  ctx.textAlign = ci.textAlign;
  // 计算可以存放的文本量
  let txt = ci.text;
  const size = ctx.measureText(txt);
  if (size.width > ci.width) {
    const percent = ci.width / size.width 
    const len = parseInt(txt.length * percent - ci.fontSize * 1.5 + "")
    txt = txt.slice(0, len);
    txt += "..."
  }
  ctx.fillText(txt, left, top)
}
