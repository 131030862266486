import { produce } from "immer"
import { createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from "react"

function withContext<T>(defaultValue: T) {
  const update: Dispatch<SetStateAction<T>> = () => { }
  const Context = createContext({
    data: defaultValue,
    update
  })

  function Provider(props: React.PropsWithChildren<{}>) {
    const [data, setData] = useState(defaultValue)
    const update = useCallback(handler => {
      setData(produce(handler) as any)
    }, [])
    return <Context.Provider value={{ data, update }} {...props} />
  }

  function useCtx() {
    const c = useContext(Context);
    if (c === undefined) throw new Error('useCtx must be inside a Provider with a value');
    return c;
  }
  return [useCtx, Provider] as const;
}

export default withContext