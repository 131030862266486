import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Input, message, Modal, Row, Tag } from 'antd';
import { useState, useCallback, type FC } from 'react';
import styled from 'styled-components';
import { names } from './config';
import ConfigExport from './ConfigExport';

const namesArr: { key: string; value: string }[] = [];
const nameKeys = Object.keys(names);
for (const key in names) {
  if (Object.prototype.hasOwnProperty.call(names, key)) {
    namesArr.push({
      key,
      value: names[key],
    });
  }
}
const Config: FC<{
  namesMore: {
    key: string;
    value: string;
  }[];
  setNamesMore: React.Dispatch<
    React.SetStateAction<
      {
        key: string;
        value: string;
      }[]
    >
  >;
}> = ({ namesMore, setNamesMore }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [row1, setRow1] = useState('');
  const [row2, setRow2] = useState('');
  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
  }, []);
  const changeRow1 = useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRow1(ev.target.value?.toUpperCase());
  }, []);
  const changeRow2 = useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRow2(ev.target.value);
  }, []);
  const handleOk = useCallback(() => {
    if (!row1 || !row2) {
      messageApi.warning('输入框不能为空');
      return;
    }
    if (namesMore.find((el) => el.key === row1) || nameKeys.includes(row1)) {
      messageApi.error('禁止设置重复简称');
      return;
    }
    setNamesMore((old) => {
      const n = [
        ...old,
        {
          key: row1,
          value: row2,
        },
      ];
      localStorage.setItem(`OperationalHedging_name`, JSON.stringify(n));
      return n;
    });
    setRow1('');
    setRow2('');
    setIsModalOpen(false);
  }, [messageApi, namesMore, row1, row2, setNamesMore]);

  const deleteIt = useCallback(
    (index: number) => {
      setNamesMore((old) => {
        old.splice(index, 1);
        localStorage.setItem(`OperationalHedging_name`, JSON.stringify(old));
        return [...old];
      });
    },
    [setNamesMore],
  );
  return (
    <>
      {contextHolder}
      <Modal title="设置名称" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Row align="middle">
          <Col span={4}>
            <LabelModel>简称：</LabelModel>
          </Col>
          <Col span={20}>
            <Input placeholder="请输入简称" value={row1} onChange={changeRow1} />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={4}>
            <LabelModel>名称：</LabelModel>
          </Col>
          <Col span={20}>
            <Input placeholder="请输入名称" value={row2} onChange={changeRow2} />
          </Col>
        </Row>
      </Modal>
      <h3>附加配置（不允许添加 “基础配置” 中的项）</h3>
      <ConfigExport namesMore={namesMore} />
      <h3>基础配置</h3>
      <Row>
        <ColCus span={24}>
          <Label>名称：</Label>
          {namesMore.map(({ key, value }, index) => (
            <Tag key={key} style={{ position: 'relative', marginBottom: '6px' }}>
              {key}
              <Splitor />
              {value}
              <Colse onClick={() => deleteIt(index)}>
                <CloseOutlined style={{ fontSize: '10px' }} />
              </Colse>
            </Tag>
          ))}
          <Button size="small" onClick={() => setIsModalOpen(true)}>
            +
          </Button>
        </ColCus>
      </Row>
      <Row>
        <Col span={24}>
          <Label>名称：</Label>
          {namesArr.map(({ key, value }) => (
            <Tag key={key} style={{ marginBottom: '6px' }}>
              {key}
              <Splitor />
              {value}
            </Tag>
          ))}
        </Col>
      </Row>
    </>
  );
};
export default Config;

const Label = styled.div`
  color: #666;
  font-size: 15px;
  margin-bottom: 8px;
  display: inline-block;
`;

const LabelModel = styled.span`
  color: #666;
  font-size: 15px;
`;

const Colse = styled.div`
  position: absolute;
  border-radius: 50%;
  right: -8px;
  top: -8px;
  width: 16px;
  height: 16px;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 6px #666;
  color: #333;
  text-align: center;
  line-height: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: rgba(0, 0, 0, 0.6);
  }
`;

const Splitor = styled.div`
  display: inline-block;
  padding: 0 8px;
  position: relative;
  height: 16px;
  &::before {
    content: '|';
    position: absolute;
    left: 4px;
    top: 0;
    color: #ccc;
  }
`;

const ColCus = styled(Col)`
  margin-bottom: 12px;
`;
