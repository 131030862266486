import Chart from "./Chart"
import { useRequest } from "@umijs/hooks"
import { Button, Input, notification, Space } from "antd"
import { useCallback, useEffect, useState } from "react"
import styled from "styled-components"


// 服务器同步数据
function serverAsyncData(data: any) {
  return {
    url: "https://sql.xcherry.top/api/socialFinancing/upload",
    method: 'post',
    data: {
      ASDFinancialInstitutions: data.ASDFinancialInstitutions || 0,
      EFDSMNFinancialEnterprises: data.EFDSMNFinancialEnterprises || 0,
      EntrustedLoans: data.EntrustedLoans || 0,
      ForeignCurrencyDenominatedLoans: data.ForeignCurrencyDenominatedLoans || 0,
      GovernmentBonds: data.GovernmentBonds || 0,
      LoansWrittenOff: data.LoansWrittenOff || 0,
      NetFinancingCorporateBonds: data.NetFinancingCorporateBonds || 0,
      RMBLoans: data.RMBLoans || 0,
      TrustLoans: data.TrustLoans || 0,
      UndiscountedBankersAcceptances: data.UndiscountedBankersAcceptances || 0,
      date: data.date,
    }
  }
}

function SocialFinancing() {
  const { data, loading, run } = useRequest(serverAsyncData, { manual: true })

  const [value, setValue] = useState<{ [key in string]: number | string }>({})
  const [fastValue, setFastValue] = useState<string>("")

  const fastInput = useCallback(({ target }) => {
    setFastValue(target.value)
    setValue(old => {
      const arr = target.value.replace(/\t/g, " ").split(" ").filter(Boolean);
      console.log(arr);
      old.date = arr[0].replace(/^(\d{4})[_.-\s](\d{2})$/, "$1-$2")
      old.RMBLoans = arr[2]
      old.ForeignCurrencyDenominatedLoans = arr[3]
      old.EntrustedLoans = arr[4]
      old.TrustLoans = arr[5]
      old.UndiscountedBankersAcceptances = arr[6]
      old.NetFinancingCorporateBonds = arr[7]
      old.GovernmentBonds = arr[8]
      old.EFDSMNFinancialEnterprises = arr[9]
      old.ASDFinancialInstitutions = arr[10]
      old.LoansWrittenOff = arr[11]
      return { ...old }
    })
  }, [])

  const setVal = useCallback((key, { target }) => {
    setValue(old => {
      if (key !== "date") {
        old[key] = parseInt(target.value)
      } else {
        old[key] = target.value.replace(/^(\d{4})[_.-\s](\d{2})$/, "$1-$2")
      }
      return { ...old }
    })
  }, [setValue])

  const upload = useCallback(() => {
    if (JSON.stringify(value) !== "{}") {
      if (/^\d{4}-\d{2}$/.test(value.date as string)) {
        if (!loading) run(value)
      } else {
        notification.open({
          message: "消息",
          description: "日期格式错误",
          placement: "bottomLeft"
        });
      }
    }
  }, [loading, run, value])

  useEffect(() => {
    if (data?.status) {
      notification.success({
        message: "消息",
        description: data.which + ":" + data.date,
        placement: "bottomLeft"
      });
      setValue({})
      setFastValue("")
    }
  }, [data, setValue, setFastValue])

  return <Container>
    <h2>录入数据</h2>
    <div>
      数据源：<Button type="link" onClick={() => window.open("http://www.pbc.gov.cn/diaochatongjisi/116219/116319/index.html")}>http://www.pbc.gov.cn/diaochatongjisi/116219/116319/index.html</Button>
    </div>
    <div className="mb">
      <Input value={fastValue} allowClear addonBefore="快速录入" onChange={fastInput} />
    </div>
    <Space className="mb" direction="horizontal">
      <Input value={value.date} allowClear addonBefore="日期" onChange={(v) => setVal("date", v)} />
      <Input value={value.RMBLoans} allowClear addonBefore="人民币贷款" onChange={(v) => setVal("RMBLoans", v)} />
      <Input value={value.ForeignCurrencyDenominatedLoans} allowClear addonBefore="外币贷款（折合人民币）" onChange={(v) => setVal("ForeignCurrencyDenominatedLoans", v)} />
      <Input value={value.EntrustedLoans} allowClear addonBefore="委托贷款" onChange={(v) => setVal("EntrustedLoans", v)} />
    </Space>
    <Space className="mb" direction="horizontal">
      <Input value={value.TrustLoans} allowClear addonBefore="信托贷款" onChange={(v) => setVal("TrustLoans", v)} />
      <Input value={value.UndiscountedBankersAcceptances} allowClear addonBefore="未贴现银行承兑汇票" onChange={(v) => setVal("UndiscountedBankersAcceptances", v)} />
      <Input value={value.NetFinancingCorporateBonds} allowClear addonBefore="企业债券" onChange={(v) => setVal("NetFinancingCorporateBonds", v)} />
      <Input value={value.GovernmentBonds} allowClear addonBefore="政府债券" onChange={(v) => setVal("GovernmentBonds", v)} />
    </Space>
    <Space direction="horizontal" className="mb">
      <Input value={value.EFDSMNFinancialEnterprises} allowClear addonBefore="非金融企业境内股票融资" onChange={(v) => setVal("EFDSMNFinancialEnterprises", v)} />
      <Input value={value.ASDFinancialInstitutions} allowClear addonBefore="存款类金融机构资产支持证券" onChange={(v) => setVal("ASDFinancialInstitutions", v)} />
      <Input value={value.LoansWrittenOff} allowClear addonBefore="贷款核销" onChange={(v) => setVal("LoansWrittenOff", v)} />
      <Button type="primary" onClick={upload} loading={loading}>添加</Button>
    </Space>
    <Chart></Chart>
  </Container>
}


const Container = styled.div`
  margin: 15px 15px 120px;
  .mb {
    margin-bottom: 15px;
  }
  .mr {
    display: inline-block;
    margin-right: 4px;
  }
`

export default SocialFinancing