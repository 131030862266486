import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Input, message, Modal, Row, Select, Tag } from 'antd';
import { useCallback, useRef, useState, useEffect, type FC } from 'react';
import styled from 'styled-components';
import { bzj, mscs, names } from './config';
import ConfigExport from './ConfigExport';

const namesArr: { key: string; value: string }[] = [];
const nameKeys = Object.keys(names);
for (const key in names) {
  if (Object.prototype.hasOwnProperty.call(names, key)) {
    namesArr.push({
      key,
      value: names[key],
    });
  }
}
const mscsArr: { key: string; name: string; value: number }[] = [];
for (const key in mscs) {
  if (Object.prototype.hasOwnProperty.call(mscs, key)) {
    mscsArr.push({
      key,
      name: names[key],
      value: mscs[key],
    });
  }
}

const bzjArr: { key: string; name: string; value: string }[] = [];
for (const key in bzj) {
  if (Object.prototype.hasOwnProperty.call(bzj, key)) {
    bzjArr.push({
      key,
      name: names[key],
      value: bzj[key],
    });
  }
}

const Config: FC<{
  namesMore: {
    key: string;
    value: string;
  }[];
  setNamesMore: React.Dispatch<
    React.SetStateAction<
      {
        key: string;
        value: string;
      }[]
    >
  >;
  mscsMore: {
    key: string;
    value: string;
    name: string;
  }[];
  setMscsMore: React.Dispatch<
    React.SetStateAction<
      {
        key: string;
        value: string;
        name: string;
      }[]
    >
  >;
  bzjMore: {
    key: string;
    value: string;
    name: string;
  }[];
  setBzjMore: React.Dispatch<
    React.SetStateAction<
      {
        key: string;
        value: string;
        name: string;
      }[]
    >
  >;
}> = ({ namesMore, setNamesMore, mscsMore, setMscsMore, bzjMore, setBzjMore }) => {
  let lastWho = useRef('');
  const mapWhoName = useRef({
    name: ['简称', '名称'],
    mscs: ['简称', '每手乘数'],
    bzj: ['简称', '保证金'],
  });
  const [who, setWho] = useState<'name' | 'mscs' | 'bzj'>('name');

  const [messageApi, contextHolder] = message.useMessage();

  const [row1, setRow1] = useState('');
  const [row2, setRow2] = useState('');
  const [showOptions, setShowOptioons] = useState<{ key: string; value: string }[]>([]);

  useEffect(() => {
    if (who !== 'name') {
      let list: string[] = [];
      if (who === 'mscs') {
        list = mscsMore.map((el) => el.key);
      } else if (who === 'bzj') {
        list = bzjMore.map((el) => el.key);
      }
      setShowOptioons(namesMore.filter((el) => !list.includes(el.key)));
    }
    if (lastWho.current !== who) {
      lastWho.current = who;
      return;
    }
    switch (who) {
      case 'name':
        localStorage.setItem(`psd_${who}`, JSON.stringify(namesMore));
        break;
      case 'mscs':
        localStorage.setItem(`psd_${who}`, JSON.stringify(mscsMore));
        break;
      case 'bzj':
        localStorage.setItem(`psd_${who}`, JSON.stringify(bzjMore));
        break;
      default:
        break;
    }
  }, [bzjMore, mscsMore, namesMore, who]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (which: 'name' | 'mscs' | 'bzj') => {
    setWho(which);
    setIsModalOpen(true);
  };
  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
  }, []);
  const handleOk = useCallback(() => {
    if (!row1 || !row2) {
      messageApi.warning('输入框不能为空');
      return;
    }
    if (who === 'name') {
      if (namesMore.find((el) => el.key === row1) || nameKeys.includes(row1)) {
        messageApi.error('禁止设置重复简称');
        return;
      }
      setNamesMore((old) => [
        ...old,
        {
          key: row1,
          value: row2,
        },
      ]);
    } else if (who === 'mscs') {
      if (isNaN(parseFloat(row2))) {
        messageApi.error('每手乘数必须是数字');
        return;
      }
      setMscsMore((old) => [
        ...old,
        {
          key: row1,
          name: namesMore.find((el) => el.key === row1)!.value,
          value: row2,
        },
      ]);
    } else if (who === 'bzj') {
      if (isNaN(parseFloat(row2))) {
        messageApi.error('保证金必须是数字');
        return;
      }
      setBzjMore((old) => [
        ...old,
        {
          key: row1,
          name: namesMore.find((el) => el.key === row1)!.value,
          value: row2 + '%',
        },
      ]);
    }
    setIsModalOpen(false);
  }, [messageApi, namesMore, row1, row2, setBzjMore, setMscsMore, setNamesMore, who]);

  const changeRow1 = useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRow1(ev.target.value?.toUpperCase());
  }, []);
  const selectRow1 = useCallback((data: string) => {
    setRow1(data);
  }, []);

  const changeRow2 = useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRow2(ev.target.value);
  }, []);

  const deleteIt = useCallback(
    (which: 'name' | 'mscs' | 'bzj', index: number) => {
      const ck = namesMore[index].key;
      switch (which) {
        case 'name':
          if (mscsMore.find((el) => el.key === ck)) {
            messageApi.error('在每手乘数中包含其引用时无法删除');
            return;
          }
          if (bzjMore.find((el) => el.key === ck)) {
            messageApi.error('在保证金中包含其引用时无法删除');
            return;
          }
          setNamesMore((old) => {
            old.splice(index, 1);
            localStorage.setItem(`psd_${which}`, JSON.stringify(old));
            return [...old];
          });
          break;
        case 'mscs':
          setMscsMore((old) => {
            old.splice(index, 1);
            localStorage.setItem(`psd_${which}`, JSON.stringify(old));
            return [...old];
          });
          break;
        case 'bzj':
          setBzjMore((old) => {
            old.splice(index, 1);
            localStorage.setItem(`psd_${which}`, JSON.stringify(old));
            return [...old];
          });
          break;

        default:
          break;
      }
      switch (which) {
        case 'name':
          break;
        case 'mscs':
          break;
        case 'bzj':
          break;
        default:
          break;
      }
    },
    [bzjMore, messageApi, mscsMore, namesMore, setBzjMore, setMscsMore, setNamesMore],
  );

  useEffect(() => {
    if (!isModalOpen) {
      setRow1('');
      setRow2('');
    }
  }, [isModalOpen]);

  return (
    <>
      {contextHolder}
      <Modal title={'设置' + mapWhoName.current[who][1]} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Row align="middle">
          <Col span={4}>
            <LabelModel>{mapWhoName.current[who][0]}：</LabelModel>
          </Col>
          <Col span={20}>
            {who === 'name' ? (
              <Input placeholder={'请输入' + mapWhoName.current[who][0]} value={row1} onChange={changeRow1} />
            ) : (
              <Select
                value={row1}
                style={{ width: '100%' }}
                fieldNames={{ label: 'value', value: 'key' }}
                placeholder={'请选择名称'}
                onChange={selectRow1}
                options={showOptions}
              />
            )}
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={4}>
            <LabelModel>{mapWhoName.current[who][1]}：</LabelModel>
          </Col>
          <Col span={20}>
            <Input
              placeholder={'请输入' + mapWhoName.current[who][1]}
              value={row2}
              onChange={changeRow2}
              addonAfter={who === 'bzj' ? '%' : ''}
            />
          </Col>
        </Row>
      </Modal>
      <ConfigExport namesMore={namesMore} />
      <br />
      <h3>附加配置（不允许添加 “基础配置” 中的项）</h3>
      <Row>
        <ColCus span={24}>
          <Label>名称：</Label>
          {namesMore.map(({ key, value }, index) => (
            <Tag key={key} style={{ position: 'relative', marginBottom: '6px' }}>
              {key}
              <Splitor />
              {value}
              <Colse onClick={() => deleteIt('name', index)}>
                <CloseOutlined style={{ fontSize: '10px' }} />
              </Colse>
            </Tag>
          ))}
          <Button size="small" onClick={() => showModal('name')}>
            +
          </Button>
        </ColCus>
        <ColCus span={24}>
          <Label>每手乘数：</Label>
          {mscsMore.map(({ key, name, value }, index) => (
            <Tag key={key} style={{ position: 'relative', marginBottom: '6px' }}>
              {name}
              <Splitor />
              {value}
              <Colse onClick={() => deleteIt('mscs', index)}>
                <CloseOutlined style={{ fontSize: '10px' }} />
              </Colse>
            </Tag>
          ))}
          <Button size="small" onClick={() => showModal('mscs')}>
            +
          </Button>
        </ColCus>
        <ColCus span={24}>
          <Label>保证金：</Label>
          {bzjMore.map(({ key, name, value }, index) => (
            <Tag key={key} style={{ position: 'relative', marginBottom: '6px' }}>
              {name}
              <Splitor />
              {value}
              <Colse onClick={() => deleteIt('bzj', index)}>
                <CloseOutlined style={{ fontSize: '10px' }} />
              </Colse>
            </Tag>
          ))}
          <Button size="small" onClick={() => showModal('bzj')}>
            +
          </Button>
        </ColCus>
      </Row>
      <h3>基础配置</h3>
      <Row>
        <ColCus span={24}>
          <Label>名称：</Label>
          {namesArr.map(({ key, value }) => (
            <Tag key={key} style={{ marginBottom: '6px' }}>
              {key}
              <Splitor />
              {value}
            </Tag>
          ))}
        </ColCus>
        <ColCus span={24}>
          <Label>每手乘数：</Label>
          {mscsArr.map(({ key, name, value }) => (
            <Tag key={key} style={{ marginBottom: '6px' }}>
              {name}
              <Splitor />
              {value}
            </Tag>
          ))}
        </ColCus>
        <ColCus span={24}>
          <Label>保证金：</Label>
          {bzjArr.map(({ key, name, value }) => (
            <Tag key={key} style={{ marginBottom: '6px' }}>
              {name}
              <Splitor />
              {value}
            </Tag>
          ))}
        </ColCus>
      </Row>
    </>
  );
};

const Label = styled.div`
  color: #666;
  font-size: 15px;
  margin-bottom: 8px;
  display: inline-block;
`;

const Colse = styled.div`
  position: absolute;
  border-radius: 50%;
  right: -8px;
  top: -8px;
  width: 16px;
  height: 16px;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 6px #666;
  color: #333;
  text-align: center;
  line-height: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: rgba(0, 0, 0, 0.6);
  }
`;
export default Config;

const LabelModel = styled.span`
  color: #666;
  font-size: 15px;
`;

const ColCus = styled(Col)`
  margin-bottom: 12px;
`;

const Splitor = styled.div`
  display: inline-block;
  padding: 0 8px;
  position: relative;
  height: 16px;
  &::before {
    content: '|';
    position: absolute;
    left: 4px;
    top: 0;
    color: #ccc;
  }
`;
