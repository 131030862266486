import produce from 'immer';
import { CommonState } from './types/common';

const obj = {
  state: {
    hasDropdown: false,
    // 触发搜索框dialog关闭
    searchDialogStatus: 1,
    // 全局搜索状态
    searchPopoverStatus: false,
    // 触发全局搜索框自动聚焦
    focusSearchPopoverInput: 1,
  },
  reducers: {
    changeDropdown(state: CommonState, hasDropdown: boolean) {
      return produce(state, (d) => {
        d.hasDropdown = hasDropdown;
      });
    },
    // 触发搜索框dialog关闭
    changeSearchDialog(state: CommonState) {
      return produce(state, (d) => {
        d.searchDialogStatus++;
      });
    },
    changeSearchPopover(state: CommonState, status: boolean) {
      return produce(state, (d) => {
        d.searchPopoverStatus = status;
      });
    },
    // 触发全局搜索框自动聚焦
    setSearchPopoverInputFocus(state: CommonState) {
      return produce(state, (d) => {
        d.focusSearchPopoverInput++;
      });
    },
  },
};
export default obj;
