export const names: { [propname: string]: string } = {
  IC: '中证500',
  IF: '沪深300',
  IH: '上证50',
  IM: '中证1000',
  FG: '玻璃',
  HC: '热卷',
  I: '铁矿石',
  J: '焦炭',
  JM: '焦煤',
  RB: '螺纹钢',
  SF: '硅铁',
  SM: '锰硅',
  ZC: '动力煤',
  AG: '白银',
  AL: '铝',
  AU: '黄金',
  CU: '铜',
  NI: '镍',
  PB: '铅',
  SN: '锡',
  ZN: '锌',
  BU: '沥青',
  EB: '苯乙烯',
  EG: '乙二醇',
  L: '塑料',
  LU: '低硫燃料油',
  MA: '甲醇',
  PG: '液化气',
  PP: '聚丙烯',
  RU: '橡胶',
  SC: '原油',
  TA: 'PTA',
  V: 'PVC',
  PF: '涤纶短纤',
  UR: '尿素',
  FU: '燃料油',
  A: '豆一',
  AP: '苹果',
  B: '豆二',
  C: '玉米',
  CF: '棉花',
  CJ: '红枣',
  CS: '玉米淀粉',
  CY: '棉纱',
  LH: '生猪',
  M: '豆粕',
  P: '棕榈油',
  RM: '菜粕',
  Y: '豆油',
  OI: '菜籽油',
  PK: '花生仁',
  JD: '鸡蛋',
  SS: '不锈钢',
  SA: '纯碱',
  SP: '纸浆',
  NR: '20号胶',
  SR: '白糖',
  SI: '工业硅',
  T: '十债',
  TS: '二债',
  TF: '五债',
  TL: '三十债',
  AO: '氧化铝',
};

// 小计分组
export const subtotalLists: string[][] = JSON.parse(
  localStorage.getItem('OperationalHedging_subtotalLists_local_config') ||
    `[
  ["IM", "IC", "IF", "IH"],
  ["TS", "TF", "T", "TL"],
  ["FG", "HC", "I", "J", "JM", "RB", "SF", "SM", "ZC", "SS"],
  ["AG", "AL", "AU", "CU", "NI", "PB", "SN", "ZN", "SI", "AO"],
  ["BU", "EB", "EG", "L", "LU", "MA", "PG", "PP", "RU", "SC", "TA", "V", "PF", "UR", "FU", "SA", "SP", "NR"],
  ["A", "AP", "B", "C", "CF", "CJ", "CS", "CY", "LH", "M", "P", "RM", "Y", "OI", "PK", "JD", "SR"]
]`,
);
