import xlsx from 'xlsx';

export function readXLSX(file: Blob): Promise<{[key: string]: number}> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      const parser = xlsx.read(this.result, { type: "string" })
      const data: { 代码: string; 结算价: string; }[] = xlsx.utils.sheet_to_json(parser.Sheets[parser.SheetNames[0]])
      const ret: { [key: string]: number } = {}
      data.forEach(item => {
        const key = item['代码'].replace(/(^[A-z]{0,5}).*$/, "$1").toUpperCase();
        if (key) {
          if (!ret[key]) ret[key] = parseFloat(item['结算价'])
          else reject("出现相同列：" + key)
        }
      })
      console.log(ret);
      resolve(ret)
    };
    reader.onerror = function () {
      reject("xls文件读取失败！")
      console.log('xls文件读取失败！');
    };
    reader.readAsText(file, 'gb2312')
  });
}