import { SERVER } from "@/config";
import useECharts from "@/utils/hooks/useECharts";
import { useRequest } from "@umijs/hooks"
import { EChartsOption } from "echarts";
import moment from "moment";
import { useEffect, useMemo } from "react";
import styled from "styled-components"


function getListHandler() {
  return {
    url: `${SERVER}/api/socialFinancing/list?t=${Date.now()}`
  }
}

function Chart() {
  const { data } = useRequest<{
    data: {
      date: string;
      RMBLoans: number;
      ForeignCurrencyDenominatedLoans: number;
      EntrustedLoans: number;
      TrustLoans: number;
      UndiscountedBankersAcceptances: number;
      NetFinancingCorporateBonds: number;
      GovernmentBonds: number;
      EFDSMNFinancialEnterprises: number;
      ASDFinancialInstitutions: number;
      LoansWrittenOff: number;
    }[]
  }>(getListHandler)

  const option = useMemo<EChartsOption>(() => {
    if (data?.data) {
      const arr = data.data.sort((x, y) => moment(x.date).isBefore(y.date) ? -1 : 1)
      const sumArr = arr.map(e =>
        e.RMBLoans +
        e.ForeignCurrencyDenominatedLoans +
        e.EntrustedLoans +
        e.TrustLoans +
        e.UndiscountedBankersAcceptances +
        e.NetFinancingCorporateBonds +
        e.GovernmentBonds +
        e.EFDSMNFinancialEnterprises +
        e.ASDFinancialInstitutions +
        e.LoansWrittenOff
      )
      return {
        grid: { right: 60, bottom: 130, left: 80 },
        legend: {
          data: ['社会融资规模增量', "人民币贷款", "外币贷款（折合人民币）", "委托贷款", "信托贷款", "未贴现银行承兑汇票", "企业债券", "政府债券", "非金融企业境内股票融资", "存款类金融机构资产支持证券", "贷款核销"],
          bottom: 6,
          right: 60
        },
        brush: {
          toolbox: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
          xAxisIndex: 0
        },
        toolbox: {
          feature: {
            magicType: {
              type: ['stack']
            },
            dataView: {}
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        xAxis: {
          data: arr.map(e => e.date),
          axisLine: { onZero: true },
          splitLine: { show: false },
          splitArea: { show: false }
        },
        yAxis: {},
        dataZoom: [
          {
            startValue: moment().subtract(2, "years").format("YYYY-MM"),
            bottom: 70
          }
        ],
        series: [
          { name: '社会融资规模增量', type: 'bar', stack: 'one', data: sumArr },
          { name: '人民币贷款', type: 'bar', stack: 'two', data: arr.map(e => e.RMBLoans) },
          { name: '外币贷款（折合人民币）', type: 'bar', stack: 'two', data: arr.map(e => e.ForeignCurrencyDenominatedLoans) },
          { name: '委托贷款', type: 'bar', stack: 'two', data: arr.map(e => e.EntrustedLoans) },
          { name: '信托贷款', type: 'bar', stack: 'two', data: arr.map(e => e.TrustLoans) },
          { name: '未贴现银行承兑汇票', type: 'bar', stack: 'two', data: arr.map(e => e.UndiscountedBankersAcceptances) },
          { name: '企业债券', type: 'bar', stack: 'two', data: arr.map(e => e.NetFinancingCorporateBonds) },
          { name: '政府债券', type: 'bar', stack: 'two', data: arr.map(e => e.GovernmentBonds) },
          { name: '非金融企业境内股票融资', type: 'bar', stack: 'two', data: arr.map(e => e.EFDSMNFinancialEnterprises) },
          { name: '存款类金融机构资产支持证券', type: 'bar', stack: 'two', data: arr.map(e => e.ASDFinancialInstitutions) },
          { name: '贷款核销', type: 'bar', stack: 'two', data: arr.map(e => e.LoansWrittenOff) }
        ]
      }
    } else {
      return {}
    }
  }, [data])
  const [chartRef, chartInstance] = useECharts(option);

  useEffect(() => {
    if (chartInstance) {
      chartInstance.setOption(option);
    }
  }, [chartInstance, option]);

  return <div>
    <h2>社融数据图</h2>
    <ECharts ref={chartRef}></ECharts>
  </div>
}

const ECharts = styled.div`
  width: 1280px;
  height: 720px;
  background: rgba(0,0,0,0.05);
`

export default Chart
