import { useCallback, useEffect } from "react"
import { DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import styled from "styled-components"
import { CSS } from "@dnd-kit/utilities";

const Box = styled.div`
  width: 1200px;
  height: 80px;
  display: flex;
`

// 拖拽项组件
function Item(props: any) {
  const { id } = props
  const { setNodeRef, listeners, transform } = useSortable({ id })
  const styles = {
    transform: CSS.Transform.toString(transform),
    border: "1px solid #ccc",
    marginRight: "8px",
    borderRadius: "4px",
    width: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "60px",
    cursor: "pointer"
  }
  return (
    <div ref={setNodeRef} {...listeners} style={styles}>{id}</div>
  )

}

function Drag({ onChange, items }: { onChange: React.Dispatch<React.SetStateAction<string[]>>, items: string[] }) {
  // 拖拽结束后的操作
  const dragEndEvent = useCallback((props: any) => {
    const { active, over } = props
    const activeIndex = items.indexOf(active.id)
    const overIndex = items.indexOf(over.id)
    onChange(items => {
      return arrayMove(items, activeIndex, overIndex)
    })
  }, [items, onChange])
  useEffect(() => {
    onChange(items)
  }, [items, onChange])
  return (
    <Box>
      <DndContext onDragEnd={dragEndEvent}>
        <SortableContext items={items}>
          {
            items.map(val => (<Item key={val} id={val} />))
          }
        </SortableContext>
      </DndContext>
    </Box>
  )
}

export default Drag