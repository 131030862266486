import { useCallback, useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
// import echarts from 'echarts/lib/echarts';
// import 'echarts/lib/component/title';
// import 'echarts/lib/chart/line';
// import 'echarts/lib/component/legend';
// import 'echarts/lib/component/tooltip';

const defaultOption = {
  xAxis: {
    type: 'category',
    data: [],
  },
  yAxis: {
    type: 'value',
  },
  series: [
    {
      data: [],
      type: 'line',
    },
  ],
};

/**
 * 创建绘制echarts图表，返回[ref, instance]将ref挂载到dom上，instance为绘图的对象，注意传入的option参数保证是不变的，后续需要修改使用instance.getOption获取操作
 * @param {Object} options ehcarts-option 仅在刚进入时初始化一次
 */
function useECharts(options: echarts.EChartsOption, rendererType: 'canvas' | 'svg' = 'canvas'): [(node: HTMLDivElement) => void, echarts.ECharts|undefined, (option: echarts.EChartsOption) => void] {
  const optionRef = useRef(options);
  const [chartNode, setChartNode] = useState<HTMLDivElement | null>();
  const chartInstance = useRef<echarts.ECharts|undefined>();
  const handleSetChartNode = useCallback<(node: HTMLDivElement) => void>(
    (node) => {
      if (node) {
        setChartNode(node);
        chartInstance.current = echarts.init(node, undefined, { renderer: rendererType });
      }
    },
    [rendererType],
  );
  const handleResize = useCallback(() => {
    chartInstance.current?.resize();
  }, []);
  useEffect(() => {
    if (chartNode) {
      if (chartInstance.current) {
        chartInstance.current.showLoading({ text: 'loading', color: '#619CFF' });
        chartInstance.current.setOption(optionRef.current || defaultOption);
        chartInstance.current.hideLoading();
        setTimeout(() => {
          if (!window.onresize) {
            window.addEventListener('resize', handleResize, false);
          }
        }, 200);
      }
    }
    return () => {
      // chartInstance.current && chartInstance.current.dispose();
      if (window.onresize) window.removeEventListener('resize', handleResize, false);
    };
  }, [chartNode, handleResize, rendererType]);

  const reInit = useCallback<(option: echarts.EChartsOption) => void>(
    (opt) => {
      if (chartNode) {
        chartInstance.current = echarts.init(chartNode, undefined, { renderer: rendererType });
        chartInstance.current.setOption(opt);
      }
    },
    [chartNode, rendererType],
  );
  // 如果首次进入页面为渲染chart，切换tab时chartInstance.current为undefined，使用第三个参数重新生成
  return [handleSetChartNode, chartInstance.current, reInit];
};
export default useECharts
