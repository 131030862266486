import { Button, Upload } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { useCallback, useState } from "react";

enum OperateState {
  idle,
  operate,
  done
}

export function useFiles() {

}

// 选文件组件
function SelectFile({ label = "选择处理文件", accept = ".csv" }) {
  const [selectFileList, setSelectFileList] = useState<UploadFile<any>[]>([])
  const [operateState, setOperateState] = useState(OperateState.idle)

  const handleSelectFileList = useCallback<(info: UploadChangeParam) => any>(info => {
    console.log(info);
    setSelectFileList(old => {
      if (old.length > info.fileList.length) {
        // 删除
        return info.fileList
      } else if (old.findIndex(file => file.name === info.file.name) === -1) {
        // 添加新项
        setOperateState(OperateState.idle)
        return [...old, info.file]
      }
      return old
    })
  }, [])

  return (
    <Upload disabled={operateState === OperateState.operate} fileList={selectFileList} onChange={handleSelectFileList} accept={accept} beforeUpload={() => false} multiple>
      <Button disabled={operateState === OperateState.operate} type="primary">{label}</Button>
    </Upload>
  )
}

export default SelectFile
