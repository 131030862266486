import { Link } from "react-router-dom"
import styled from "styled-components"

const Container = styled.div`
 margin: 15px;
`

const Go = styled(Link)`
  display: block;
  margin-bottom: 5px;
`

function Home() {

  return (
    <Container>
      <Go to="/psd">头寸统计</Go>
      <Go to="/operationalHedging">操作对冲</Go>
      <Go to="/prd">价格信息</Go>
      <Go to="/SocialFinancing">社会融资规模</Go>
      <Go to="/settlementSheet">结算单</Go>
      <Go to="/reconciliationTool">对账工具</Go>
      <Go to="/Excel">表格</Go>
      {/* <Go to="/Despair">绝望篇</Go> */}
    </Container>
  )
}

export default Home