import { Rect } from "../types";

// 计算水平的标题名称(index为自然数)
export function calcTitle(index: number) {
  if (index) {
    let zero = 0;
    return String.fromCharCode.apply(null, index.toString(26).split("").reverse().map(idx => {
      const code = idx.charCodeAt(0) - zero
      zero = 0;
      if (code < 48) {
        zero = 1
        return code + 42
      }
      if (code === 48) {
        zero = 1
        return 90
      }
      if (code > 65) return code - 22
      return code + 16
    }).reverse()).substring(zero)
  }
  return ""
}

// 获取位置表格所有信息
export function getRect(dom: HTMLCanvasElement | null): Rect | null {
  if (dom) {
    const obj = dom.getBoundingClientRect()
    return {
      width: obj.width, // 宽高同offset
      height: obj.height,
      left: obj.left,
      right: obj.right,
      top: obj.top,
      bottom: obj.bottom,
      x: obj.x,
      y: obj.y,
      clientWidth: dom.clientWidth, // 不带滚动条
      clientHeight: dom.clientHeight,
      offsetWidth: dom.offsetWidth, // 带滚动条
      offsetHeight: dom.offsetHeight,
      scrollWidth: dom.scrollWidth, // 滚动内容
      scrollHeight: dom.scrollHeight,
    }
  }
  return null
}
