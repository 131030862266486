import styled from "styled-components"
import { useCallback, useEffect, useMemo, useState } from "react";
import useECharts from "@/utils/hooks/useECharts";
import { EChartsOption } from "echarts";
import { useRequest } from '@umijs/hooks';
import { Radio, RadioChangeEvent } from "antd";
import moment from "moment";
import { SERVER } from "@/config";

enum Type {
  SYCL = 'sycl',
  LSTL = 'lstl',
  SYQ = 'syq',
  DJG = 'djg',
  QYHL = 'qyhl',
  TSL = 'tsl',
  GM = 'gm',
  DJY1 = 'djy1',
  DJY2 = 'djy2',
  LF = 'lf',
  EMC = 'emc',
  DMC = 'dmc',
  TB = 'tb'
}

// 服务器同步数据
function serverAsyncData() {
  return {
    url: `${SERVER}/api/prdPrice/getSpotGoodsHistoryPrice?t=${Date.now()}`,
    method: 'get'
  }
}

// 获取某种表数据
function getDataByName(name: string, type: string, origin: string | undefined) {
  return {
    url: `${SERVER}/api/prdPrice/list?name=${name}&type=${type}` + (origin && `&origin=${origin}`),
    method: 'get'
  }
}

function Prd() {

  const types = useMemo(() => [
    { name: "三元材料", origin: "国产", specs: "111/动力", key: "sycl" },
    { name: "磷酸铁锂", origin: "国产", specs: "动力型", key: "lstl" },
    { name: "三元前驱体", origin: "国产", specs: "111", key: "syq" },
    { name: "电解钴", origin: "国产", specs: "", key: "djg" },
    { name: "氢氧化锂", origin: "国产", specs: "电池级", key: "qyhl" },
    { name: "碳酸锂", origin: "国产", specs: "工业级", key: "tsl" },
    { name: "隔膜", origin: "国产中端", specs: "", key: "gm" },
    { name: "电解液", specs: "三元/圆柱/2600mAh", key: "djy1" },
    { name: "电解液", specs: "磷酸铁锂", key: "djy2" },
    { name: "六氟磷酸锂", origin: "国产", specs: "六氟磷酸锂", key: "lf" },
    { name: "EMC", origin: "国产", specs: "电池级", key: "emc" },
    { name: "DMC", origin: "国产", specs: "电池级", key: "dmc" },
    { name: "铜箔", origin: "国产", specs: "6um电池级", key: "tb" },
  ], [])

  const typeObj: { [key in Type]: { name: string; specs: string; key: string; origin?: string } } = useMemo(() => {
    const arr = types.map(e => ({ [e.key]: e }))
    return Object.assign.apply(null, arr as any);
  }, [types])

  const { data, loading } = useRequest(serverAsyncData)

  const { data: optionRes, loading: optionloading, run } = useRequest(getDataByName, { manual: true })
  console.log(optionRes, optionloading);
  const option = useMemo<EChartsOption>(() => {
    if (optionRes?.status) {
      const obj = optionRes.data
      const data = obj.data
      return {
        xAxis: {
          type: 'category',
          data: data.map((e: any) => e.date).reverse()
        },
        yAxis: {
          name: obj.unit,
          type: 'value'
        },
        dataZoom: [
          {
            startValue: moment().subtract(0.5, "years").format("YYYY-MM-DD")
          },
          {
            type: 'inside'
          }
        ],
        tooltip: {
          show: true,
          trigger: "axis"
        },
        series: [
          {
            data: data.map((e: any) => e.value).reverse(),
            type: 'line'
          }
        ]
      };
    }
    return {}
  }, [optionRes]);
  const [chartRef, chartInstance] = useECharts(option);

  useEffect(() => {
    if (chartInstance) {
      chartInstance.setOption(option);
    }
  }, [chartInstance, option]);

  const [type, setType] = useState<Type>(Type.SYCL)

  useEffect(() => {
    const { name, specs, origin = "" } = typeObj[type]
    run(name, specs, origin)
  }, [type, run, typeObj])

  const handleTypeChange = useCallback(({ target: { value } }: RadioChangeEvent) => {
    setType(value)
  }, [])

  return (
    <Container>
      <Loading>服务器端数据更新：{loading ? "更新中" : (data?.status ? "更新成功" : "更新失败")}</Loading>
      <Flex>
        <Radio.Group className="radio" value={type} onChange={handleTypeChange}>
          {
            types.map(e => (
              <Radio.Button value={e.key} key={e.key}>
                {e.name}{e.specs && <span className="radio-desc">({e.specs})</span>}
              </Radio.Button>
            ))
          }
        </Radio.Group>
        <ECharts ref={chartRef}></ECharts>
      </Flex>
    </Container>
  )
}

const Loading = styled.div`
  margin-bottom: 12px;
`

const Flex = styled.div`
  display: flex;
`

const Container = styled.div`
  margin: 15px;
  .radio {
    display: flex;
    flex-direction: column;
    width: 220px;
    user-select: none;
    margin-right: 15px;
  }
  .radio-desc {
    color: gray;
  }
  .ant-radio-button-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: -1px;
    width: 158px;
    border-left-width: 1px 
  }
  .ant-radio-button-wrapper-checked {
    .radio-desc {
      color: #40a9ff;
    }
  }
`

const ECharts = styled.div`
  width: 1280px;
  height: 720px;
  background: rgba(0,0,0,0.05);
`

export default Prd