import { List } from ".";
import { subtotalLists } from "./config";
const parse = require('csv-parse/lib/sync')


export const totalLists = ([] as string[]).concat(...subtotalLists)

export function readCSV(file: Blob): Promise<List> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      const parser = parse(this.result, {
        on_record: (row: string[]) => {
          const key = row[0].replace(/(^[A-z]{0,5}).*$/, "$1").toUpperCase()
          if (totalLists.find(e => e === key)) {
            return {
              key,
              vcl: parseInt(row[7]) * (row[1] === "买" ? 1 : -1),
              czsz: parseInt(row[5])
            }
          } else {
            return null
          }
        }
      })
      // console.log(parser);
      resolve(parser)
    };
    reader.onerror = function () {
      reject("csv文件读取失败！")
      console.log('csv文件读取失败！');
    };
    reader.readAsText(file, 'gb2312')
  })
}